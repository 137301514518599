import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResetPasswordDto, ResetPasswordResDto } from 'api/users.api';

import { ExtraParamsThunkType, RequestErrors } from '../../store/types';
import { User } from './types';

export const getUsersAsync = createAsyncThunk<Array<User>, void, ExtraParamsThunkType<RequestErrors>>(
  'users/getAll',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.UsersApi.getUsers();
      return data.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteUserAsync = createAsyncThunk<void, string, ExtraParamsThunkType<RequestErrors>>(
  'users/deleteUser',
  async (userId: string, { extra: { api }, rejectWithValue }) => {
    try {
      await api.UsersApi.deleteUser(userId);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetPasswordAsync = createAsyncThunk<
  ResetPasswordResDto,
  ResetPasswordDto,
  ExtraParamsThunkType<RequestErrors>
>('users/resetPassword', async (userData: ResetPasswordDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.UsersApi.resetPassword(userData);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
