import { Check, Close, Download, LockReset } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { api } from 'api';
import { selectUsers } from 'features/users/selector';
import { User } from 'features/users/types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { deleteUserAsync, getUsersAsync, resetPasswordAsync } from '../features/users/thunks';
import { useAppDispatch } from '../store/reducers';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);

  useEffect(() => {
    dispatch(getUsersAsync());
  }, []);

  useEffect(() => {
    setCurrentUsers(users);
  }, [users]);

  function ConvertToDate(isoString: string) {
    const date = new Date(isoString);
    return `${date.toDateString()}-${date.toLocaleTimeString()}`;
  }

  const downloadChat = async (userId: string) => {
    try {
      const element = document.createElement('a');
      const data = await api.ChatApi.getChat({ userId });
      const file = new Blob([JSON.stringify(data.data, null, 4)], { type: 'application/json' });
      element.href = URL.createObjectURL(file);
      element.download = `${userId}.json`;
      element.click();
    } catch (e) {
      setShowErrorAlert(true);
      setAlertMessage('Downloading chat failed');
    } finally {
      setShowDeleteModal(false);
      setSelectedUser(undefined);
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await dispatch(deleteUserAsync(userId));
      setShowSuccessAlert(true);
      setAlertMessage('User successfully deleted.');
    } catch (e) {
      setShowErrorAlert(true);
      setAlertMessage('Deleting user failed');
    } finally {
      setShowDeleteModal(false);
      setSelectedUser(undefined);
    }

    await dispatch(getUsersAsync());
  };

  const resetPasswordOfUser = async (email: string) => {
    try {
      await dispatch(resetPasswordAsync({ email }));
      setShowSuccessAlert(true);
      setAlertMessage('Password successfully reset.');
    } catch (error) {
      setShowErrorAlert(true);
      setAlertMessage('Resetting password failed');
    } finally {
      setShowResetPasswordModal(false);
      setSelectedUser(undefined);
    }

    await dispatch(getUsersAsync());
  };

  return (
    <>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Modified</TableCell>
            <TableCell>
              Last Login <TableSortLabel active onClick={() => setCurrentUsers(currentUsers.slice().reverse())} />
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentUsers.map((user, index) => (
            <TableRow key={`user_${index}`}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.userId}</TableCell>
              <TableCell>{user.isVerified ? <Check /> : <Close />}</TableCell>
              <TableCell>{ConvertToDate(user.createdAt)}</TableCell>
              <TableCell>{ConvertToDate(user.modifiedAt)}</TableCell>
              <TableCell>{ConvertToDate(user.lastLogin)}</TableCell>
              <TableCell>
                <DeleteIcon
                  onClick={() => {
                    setShowDeleteModal(true);
                    setSelectedUser(user);
                  }}
                />
                <Download
                  onClick={() => {
                    downloadChat(user.userId);
                  }}
                />
                <LockReset
                  onClick={() => {
                    setShowResetPasswordModal(true);
                    setSelectedUser(user);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal
        open={showDeleteModal}
        onClose={() => {
          setSelectedUser(undefined);
          setShowDeleteModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete user?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete the selected user {selectedUser?.name}?
          </Typography>

          <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setSelectedUser(undefined);
                setShowDeleteModal(false);
              }}>
              Cancel
            </Button>
            <Button onClick={() => selectedUser && deleteUser(selectedUser.userId)}>Confirm</Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showResetPasswordModal}
        onClose={() => {
          setSelectedUser(undefined);
          setShowResetPasswordModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reset password of user?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to reset the password of the selected user {selectedUser?.name}?
          </Typography>

          <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setSelectedUser(undefined);
                setShowResetPasswordModal(false);
              }}>
              Cancel
            </Button>
            <Button onClick={() => selectedUser && resetPasswordOfUser(selectedUser.email)}>Confirm</Button>
          </Box>
        </Box>
      </Modal>

      {showErrorAlert && (
        <Alert
          severity="error"
          onClose={() => setShowErrorAlert(false)}
          sx={{ position: 'fixed', top: 0, zIndex: 10, width: '97%' }}>
          {alertMessage}
        </Alert>
      )}

      {showSuccessAlert && (
        <Alert
          severity="success"
          onClose={() => setShowSuccessAlert(false)}
          sx={{ position: 'fixed', top: 0, zIndex: 10, width: '97%' }}>
          {alertMessage}
        </Alert>
      )}
    </>
  );
};

export default UsersPage;
